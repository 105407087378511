<template>
  <DialogFormWrapper hide-actions>
    <template #form>
      <v-form ref="checkPriceForm">
        <v-row class="mt-4">
          <v-col cols="6">
            <gmap-autocomplete
              @place_changed="getAddressData"
              :options="autocompleteOptions"
              select-first-on-enter
            >
              <template #input="slotProps">
                <v-text-field
                  outlined
                  prepend-inner-icon="place"
                  placeholder="Wyszukaj adres"
                  label="Adres wyszukiwania"
                  :value="address"
                  ref="input"
                  validate-on-blur
                  :rules="[rules.required]"
                  @listeners="slotProps.listeners"
                  @attrs="slotProps.attrs"
                >
                  <template #prepend-inner>
                    <Icon
                      name="search"
                      size="small"
                      class="mr-2"
                    />
                  </template>
                </v-text-field>
              </template>
            </gmap-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              outlined
              :items="containerTypes"
              label="Typ kontenera"
              placeholder="Wybierz typ kontenera"
              v-model="queryParams.containerTypeIds"
              validate-on-blur
              item-text="name"
              multiple
              item-value="id"
              append-icon="mdi-chevron-down"
              clearable
              @change="checkPrice"
            >
              <template #selection="{ item, index }">
                <MultiselectChoices
                  :index="index"
                  :label="item.name"
                  :visible-items="5"
                  :array-length="queryParams.containerTypeIds.length"
                />
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-select
              outlined
              label="Promień wyszukiwania"
              v-model="queryParams.location.radius"
              :menu-props="{offsetY: true}"
              :items="radiusOptions"
              item-text="text"
              item-value="value"
              append-icon="mdi-chevron-down"
              :rules="[rules.required]"
              @change="checkPrice"
            />
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              outlined
              :items="debrisAndAggregateTypes"
              label="Typ odpadu / kruszywa"
              placeholder="Wybierz typ odpadu / kruszywa"
              v-model="queryParams.debrisTypeIds"
              validate-on-blur
              :item-text="getDebrisString"
              item-value="id"
              append-icon="mdi-chevron-down"
              clearable
              multiple
              :filter="searchDebrisType"
              @change="checkPrice"
            >
              <template #selection="{ item, index }">
                <span
                  v-if="index < 1"
                  class="input--multiple"
                >{{ getDebrisString(item) }}</span>
                <span v-if="index === 1">
                  (+{{ queryParams.debrisTypeIds.length - 1 }})
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-data-table
              ref="resultsTable"
              class="price-check-table"
              :headers="resultsTableHeaders"
              :items="ordersList"
              :items-per-page="-1"
              :loading="isProcessing"
              hide-default-footer
              fixed-header
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import MultiselectChoices from '../Elements/MultiselectChoices.vue'
import rules from '../../utils/validators'
import api from '../../api/v1'
import { formatValue } from '../../utils/typesEnum'
import { searchDebrisType, getDebrisString, getGmapsAddressParts } from '../../utils'
import { mapState, mapActions } from 'vuex'

const radiusOptions = [
  { value: 500, text: '500 m' },
  { value: 1000, text: '1 km' },
  { value: 2000, text: '2 km' },
  { value: 3000, text: '3 km' },
  { value: 5000, text: '5 km' },
  { value: 10000, text: '10 km' },
  { value: 15000, text: '15 km' },
]
const autocompleteOptions = {
  fields: ['adr_address', 'formatted_address', 'geometry.location'],
  componentRestrictions: {
    country: ['pl']
  }
}
const resultsTableHeaders = [
  { text: 'Adres', value: 'formattedAddress', width: 150 },
  { text: 'Kontener', value: 'containerType', width: 110 },
  { text: 'Odpad/Kruszywo', value: 'debrisType' },
  { text: 'Klient', value: 'client' },
  { text: 'Cena transportu netto', value: 'transportNetValue', width: 180 },
  { text: 'Cena usługi netto', value: 'debrisNetValue', width: 150 },
  { text: 'Cena brutto', value: 'totalGrossValue', width: 120 },
]

export default {
  components: {
    DialogFormWrapper,
    MultiselectChoices,
  },
  data: () => ({
    queryParams: {
      location: {
        lat: null,
        lng: null,
        radius: 2000,
      },
      containerTypeIds: [],
      debrisTypeIds: []
    },
    ordersList: [],
    address: null,
    isProcessing: false,
    radiusOptions,
    rules,
    autocompleteOptions,
    resultsTableHeaders,
  }),
  computed: {
    ...mapState({
      department: state => state.core.department,
      containerTypes: state => state.containerTypes.items,
      debrisTypes: state => state.debrisTypes.items,
      aggregateTypes: state => state.aggregateTypes.items,
    }),
    debrisAndAggregateTypes () {
      return [...this.debrisTypes, ...this.aggregateTypes]
        .sort((a, b) => a.name?.localeCompare(b.name))
    }
  },
  mounted () {
    this.getContainerTypes()
    this.getDebrisTypes({ params: { aggregate: false } })
    this.getAggregateTypes({ params: { endpoint: 'debrisTypes', aggregate: true } })
  },
  methods: {
    ...mapActions({
      getContainerTypes: 'containerTypes/getItems',
      getDebrisTypes: 'debrisTypes/getItems',
      getAggregateTypes: 'aggregateTypes/getItems'
    }),
    searchDebrisType,
    getDebrisString,
    getAddressData (e) {
      const { formattedAddress, lat, lng } = getGmapsAddressParts(e)
      this.queryParams.location.lat = lat
      this.queryParams.location.lng = lng
      this.address = formattedAddress
      this.checkPrice()
    },
    checkPrice () {
      const params = {
        ...this.queryParams,
        departmentId: this.department.id
      }
      if (this.$refs.checkPriceForm.validate()) {
        this.isProcessing = true
        api.getSimilarOrdersPrices(params)
          .then((resp) => {
            this.ordersList = resp.data.map((order) => ({
              formattedAddress: order.address.formattedAddress,
              containerType: order.containerType.name,
              debrisType: order.debrisType.displayName,
              client: order.client.name,
              transportNetValue: formatValue(order.payment.transportNetValue, 'price'),
              debrisNetValue: formatValue(order.payment.debrisNetValue, 'price'),
              totalGrossValue: formatValue(order.payment.totalGrossValue, 'price'),
            }))
          })
          .finally(() => {
            this.isProcessing = false
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.price-check-table {
  border: 1px solid $color-indicator-gray;
  &::v-deep .v-data-table__wrapper {
    border-radius: 6px !important;
    height:360px;
  }
}

.input--multiple{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 320px;
}
</style>
